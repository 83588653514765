#artwork-listing{
	margin-top: 3em;
	.artwork-entry{
		margin-bottom: 1em;
		text-align: center;
		font-size: 1.2em;
		font-weight: 200;
		.artist-name{
			text-transform: uppercase;
			margin-top: 1em;
			margin-bottom: -0.1em;
			font-weight: 400;
		}
		.title{
			font-style: italic;
			margin-bottom: 1.8em;
		}
		.image{
			min-width: 300px;
			width: inherit;
			height: 300px;
			line-height: 300px;
			img{
				vertical-align: middle;
				max-width: 300px;
				max-height: 300px;
			}
		}
	}
}

#artwork-entry{
	margin-top: 3em;
	margin-bottom: 1em;
	h1{
		font-size: 1.8em;
		margin: 0;
		color: #000;
	}
	.artist-dates{
		font-size: 1.4em;
		margin-bottom: .5em;
		color: #000;
	}
	.title{}
	.medium{}
	.dimensions{}
	.sold-status{
		color: #000;
		font-size: 1.4em;
		margin-top: 1.2em;
	}
	.section-title{
		text-transform: uppercase;
		color: #999;
		margin-top: 1.2em;
	}
	.provenance{}
	.exhibitions{}
	.literature{}
	.additional-info{}
	.image{
		margin-bottom: 1em;
		text-align: center;
		img{
			object-fit: cover;
			max-width: 600px;
			max-width: 100%;
			max-height: 600px;
		}
	}
}

@media (min-width: 690px) {
  /* Target devices smaller than 690px. */
  #artwork-entry .details{
  	order: -1;
  }
}