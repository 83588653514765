
#splash-bg{
	background-image: url("/assets/images/splash.jpg");
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-position: center;
	background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
	overflow: hidden;
	z-index: -1;
}

#splash-container{
	text-align: center;
	width: 100%;
	height: 100%;
	display: table;
	.message{
		vertical-align:middle;
  	display: table-cell;
  	color: #333;
  	text-transform: uppercase;
  	font-size: 2.5em;
  	font-weight: 600;
  	h1{
  		font-size: 1.5em;
  		font-weight: 600;
  	}
  	a{
  		color: #fff;
  	}
		.logo{
			img{
				max-width: 100px;
			}
		}
	}
}