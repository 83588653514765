@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,600;1,200;1,300;1,400;1,600&family=Open+Sans:ital,wght@0,300;1,300&display=swap');

@import 'bootstrap/bootstrap';
@import 'splash';
@import 'navbar';
@import 'footer';
@import 'publications';
@import 'artwork';
@import 'about';
@import 'artists';

html,body,.container{
	height: 100%;
}

body{
	  color: #666;
    font-family: "Karla", "Helvetica", Arial, Sans-Serif;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.425;
}

a{
	color: #000;
	text-decoration: none;
	&:hover{
		color: #999;
		text-decoration: none;
	}
}

.container{
  max-width: 1080px;
}

.page-notice{
	text-align: center;
	text-transform: uppercase;
	font-size: 1.2em;
	font-weight: 200;
	width: 70%;
	margin: 0 auto;
	font-weight: 400;
	font-size: .9em;
}

@media print{    
  .no-print, .no-print * {
    display: none !important;
  }
}