#navbar{
	font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 3px;
	padding-top: 1.5em;
	margin-bottom: 1.5em;
	text-align: center;
	.logo{
		img{
			width: 100%;
			max-width: 330px;
		}
	}
	.menu{
		margin-top: 1em;
		ul{
			padding: 0;
			margin: 0;
		}
		li{
			list-style-type: none;
			display: inline-block;
			margin: 0;
			margin-left: 2.2em;
			margin-right: 2.2em;
			a{
				border: none;
		    color: #000;
		    display: block;
		    padding: .3em 1em;
		    position: relative;
			}
		}
	}
}

@media (min-width: 420px) {
  /* Target devices wider than 420px. */
  #navbar .menu li{
  	margin-left: 1em;
  	margin-right: 1em;
  }
}

@media (min-width: 1080px) {
  /* Target devices wider than 1080px. */
  #navbar{
  	padding-top: 2.5em;
  	margin-bottom: 2.5em;
  	ul{
  		text-align: right;
  	}
  	.menu li{
	  	margin-left: 0em;
	  	margin-right: 0em;
	  }
  }
}