#publications{
	font-size: 1.2em;
	font-weight: 200;
	margin-top: 3em;
	ul{
		padding: 0;
		li{
			margin: 0;
			list-style: none;
			margin-bottom: 1.2em;
			.cover{
				text-align: center;
				margin: 1.2em;
			}
			img{
				max-width: 350px;
			}
			.description{
				
			}
			.availability{
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}	
}

@media (max-width: 768px) {
  /* Target devices smaller than 768px. */
  #publications .order-last{
  	order: -1;
  }
}

@media (min-width: 768px) {
  /* Target devices larger than 768px. */
  #publications{
  	ul{
  		li{
  			margin-bottom: 3em;
  			.cover{
  				margin: 0;
  			}
  		}
  	}
  }
}