#artist-list{
	text-transform: uppercase;
	font-size: 1.2em;
	font-weight: 200;
	margin-top: 3em;
	margin-bottom: 4em;
	ul{
		column-count: 3;
		padding: 0;
		li{
			list-style: none;
			a{
				font-weight: 400;
			}
		}
	}
}

@media (min-width: 420px) {
  /* Target devices wider than 420px. */
  #artist-list ul{
  	column-count: 2;
  }
}

@media (min-width: 800px) {
  /* Target devices wider than 800px. */
  #artist-list ul{
  	column-count: 3;
  }
}

@media (max-width: 420px) {
  /* Target devices smaller than 420px. */
  #artist-list ul{
  	column-count: 1;
  }
}